
import EntityCard from "@/components/EntityCard.vue";
import CreateOrganizationModal from "@/components/modals/CreateOrganizationModal.vue";
import {defineComponent, ref} from "vue";
import {ProductionMembership} from "@/models/productions/ProductionMembership";
import {OrganizationMembership} from "@/models/organizations/OrganizationMembership";
import {MembershipStatus} from "@/models/enums/MembershipStatus";
import Button from "@/components/UI/Button.vue";
import {bus} from "@/modules/eventBus";
import {useUser} from "@/store/pinia/userStore";
import { useApplication } from "@/store/pinia/applicationStore";
import {Invitation} from "@/models/user/Invitation";

export default defineComponent({
  name: "EntitiesTab",
  components: {Button, CreateOrganizationModal, EntityCard},
  setup() {
    const createOrganizationModal = ref<InstanceType<typeof CreateOrganizationModal>>();
    const invokeCreateOrganization = () => {
      createOrganizationModal.value?.invoke()
    }
    const userStore = useUser();
    const applicationStore = useApplication();
    return {createOrganizationModal, invokeCreateOrganization, userStore, applicationStore}
  },
  mounted() {
    this.userStore.fetchProductionMemberships();
    this.userStore.fetchOrganizationMemberships();
    this.userStore.fetchInvitations();
  },
  methods: {
    toggleRequestAccess() {
      bus.emit('modals:request:show')
    },
    viewInvitation(invitation: Invitation) {
      bus.emit('modals:invitations:view', invitation)
    }
  },
  computed: {
    activeProductions(): Array<ProductionMembership> {
      return this.userStore.productions.filter(x => x.status === MembershipStatus.Active)
    },
    activeOrganizations(): Array<OrganizationMembership> {
      return this.userStore.organizations.filter(x => x.status === MembershipStatus.Active)
    },
  }
})
