
import ModalBase from "@/components/ModalBase.vue";
import {defineComponent, ref} from "vue";
import {UpsertOrganizationCommand} from "@/models/organizations/UpsertOrganizationCommand";
import {OrganizationService} from "@/services/OrganizationService";
import {Organization} from "@/models/organizations/Organization";
import Selector2 from "@/components/inputs/SSelect2.vue";
import Button from "@/components/UI/Button.vue";
import useVuelidate from "@vuelidate/core";
import {email, required, url} from "@vuelidate/validators";
import { useUser } from "@/store/pinia/userStore";
import {WebAnalytics} from "@/modules/webAnalytics";

export default defineComponent({
  components: {Button, Selector2, ModalBase},
  setup() {
    const modal = ref<InstanceType<typeof ModalBase>>();
    const invoke = () => {
      modal.value?.toggleOpen()
    }
    const closeModal = () => {
      modal.value?.toggleClosed()
    }
    const userStore = useUser()
    return {modal, invoke, closeModal, v$: useVuelidate(), userStore}
  },
  data() {
    return {
      organizationCommand: {} as UpsertOrganizationCommand,
      organization: {} as Organization,
      orgPhone: '',
      isLoading: false,
      organizationIndustryOptions: [
        {
          text: 'Other',
          id: 0
        },
        {
          text: 'Professional Theatre',
          id: 1
        },
        {
          text: 'Community Theatre',
          id: 2
        },
        {
          text: 'Education',
          id: 3
        },
        {
          text: 'Dance',
          id: 4
        },
        {
          text: 'Choral',
          id: 5
        },
        {
          text: 'Tour',
          id: 6
        },
        {
          text: 'Individual',
          id: 7
        }
      ],
    }
  },
  validations(){
    return {
      organizationCommand: {
        name: {required},
        email: {email},
        website: {url}
      }
    }
  },
  methods: {
    add() {
      this.v$.organizationCommand.$touch()
      if (!this.v$.organizationCommand.$invalid) {
        this.isLoading = true
        OrganizationService.create(this.organizationCommand).then(response => {
          this.userStore.fetchUser()
          this.organization = response.data
          this.isLoading = false
          this.closeModal()
          this.$router.push({name: 'organization-onboarding', params: {organizationId: response.data.organizationId}})
        }).finally(() => {
          this.isLoading = false
          WebAnalytics.trackFlexible('Created Organization', {
            withSignup: false
          })
        })
      }
    }
  }
})
