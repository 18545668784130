<template>
  <div @click="navigate"
       class="card border border-2 border-gray-300 border-hover flex-center h-100 ribbon ribbon-top ribbon-vertical">
    <el-tooltip v-if="!this.entity.productionId" content="Organization" placement="top">
      <div class="ribbon-label">
        <i class="fad fa-sitemap text-white"></i>
      </div>
    </el-tooltip>
    <div class="card-body d-flex flex-center flex-md-column">
      <img v-if="entity.photo !== '' && entity.photo !== null" :src="entity.photo" alt="image"
           class="rounded mb-md-4 img-fluid w-50 w-md-100"/>
      <div class="d-none d-md-flex flex-md-column">
        <div class="fs-2 fw-bolder text-white entity-title">
          {{ entity.name }}
        </div>
        <div v-if="entity.organizationName" class="text-gray-800 fw-bold fs-4 mt-1 entity-title">{{
            entity.organizationName
          }}
        </div>
      </div>
      <div class="d-flex d-md-none flex-column ms-4">
        <div class="fs-2 fw-bolder text-white entity-title">
          {{ entity.name }}
        </div>
        <div v-if="entity.organizationName" class="text-gray-800 fw-bold fs-4 mt-1 entity-title">{{
            entity.organizationName
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EntityCard",
  props: ['entity'],
  methods: {
    navigate() {
      if (this.entity.productionId) {
        this.$router.push({
          name: 'production-overview',
          params: {
            productionId: this.entity.productionId
          }
        });
      } else {
        this.$router.push({
          name: 'organization-overview',
          params: {
            organizationId: this.entity.organizationId
          }
        });
      }
    }
  }
}
</script>

<style scoped>
.card {
  cursor: pointer;
}

.entity-title {
  text-align-last: center;
  text-align: center;
}
</style>
